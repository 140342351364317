.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./fonts/Inter-Regular.woff2) format('woff2');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'InterBold';
  src: local('InterBold'), url(./fonts/Inter-Medium.woff2) format('woff2');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


.logoFontSize {
  font-size: 40px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100vw;
}

.playlistCell {
  background-color: white;
  overflow: hidden;
  height: 50px;
  line-height: 50px;
  color: #282c34;
  display: flex;
  border: 1px solid lightgrey;
  border-top: none;
  border-left: none;
  border-right: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.buttonClass {
  width: 100%;
  height: 40px;
  border-radius: 0px;
  border-color: white;
  border-width: 0px;
  border-style: solid;
  border-radius: 10px;
}

.buttonClass:enabled {
  background-color: #002e60;
  color: white;
}

.buttonClass:disabled {
  background-color: #aaadaf;
  color: black;
}

.buttonClass:active {
  background-color: white;
}

.searchContainer {
  position: relative;
  width: 80%;
}

.searchBar {
  /* width: 100%; */
  height: 40px;
  font-size: 20px;
  font-family: "Inter";
  border-radius: 10px;
  border-color: #002e60;
  border-style: solid;
  border-width: 2px;
  /* box-sizing: border-box; */
  /* Ensure padding and border are included in the element's total width and height */
}

.clearButton {
  /* position: absolute; */
  /* right: 0; */
  /* top: -10; */
  /* height: 100%; */
  /* width: 50px; */
  background-color: transparent;
  color: black;
  border: none;
  /* cursor: pointer; */
  font-size: 30px;
  /* align-items: center; */
}

.timerTextDiv {
  width: 100%;

  display: flex;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
}

.startButtonsDiv {
  height: 200px;
}

.totalDurationDiv {
  height: 40px;
}

.songChangeDiv {
  height: 40px;
}

.fontFamilyRegular {
  font-family: "Inter";
}

.fontFamilyBold {
  font-family: "InterBold";
}

.fontColorLight {
  color: white;
}

.fontColorDark {
  color: #002e60;
}

.smallFontSize {
  font-size: 20px;
}

.bigFontSize {
  font-size: 80px;
}

/* .loginComponentDiv {
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
} */

/* Mobile custom CSS */
@media (max-device-width: 1080px),
(max-device-height: 1080px) {

  
}

/* Desktop custom CSS */
@media (min-device-width: 1080px),
(min-device-height: 1080px) {
 
}